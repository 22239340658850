<template>
    <div class="login-pages" ref="login">
        <div ref="d3wrap"></div>
        <div class="login-window" :class="{'action':isLogin}">
            <p class="title">用户登录</p>
            <el-form ref='loginForm' class="login-form" :model="formData" :rules="rules">
                <!--<el-form-item prop="companycode">-->
                <!--<el-input v-model="formData.companyCode" placeholder="公司编码">-->
                <!--<i slot="prefix" class="cw-icon cw-icon-zhanghao"></i>-->
                <!--</el-input>-->
                <!--</el-form-item>-->
                <el-form-item prop="username">
                    <el-input v-model="formData.username" placeholder="账号">
                        <i slot="prefix" class="cw-icon cw-icon-zhanghao"></i>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="formData.password" type="password" placeholder="密码">
                        <i slot="prefix" class="cw-icon cw-icon-mima"></i>
                    </el-input>
                </el-form-item>
                <!--<el-form-item prop="code">-->
                    <!--<el-input class="code-input" v-model="formData.randomText" placeholder="验证码">-->
                        <!--<template slot="append">-->
                            <!--<img class="code-img" :src="codeUrl+formData.uuid" alt="验证码"-->
                                 <!--@click="hanndleChangeCode">-->
                        <!--</template>-->
                    <!--</el-input>-->
                <!--</el-form-item>-->
                <div class="login-button-wrapper">
                    <el-button class="login-button" style="width: 100%;" type="primary" :loading="isLogin"
                               @click="handleLogin"
                               :disabled="!isSubmit" round>登录
                    </el-button>
                </div>
                <p class="cms-title"><span>从网科技后台管理系统</span></p>
            </el-form>
        </div>
    </div>
</template>

<script>

    import {mapActions} from 'vuex'
    import {setUserId} from '../../utils/auth'
    import {uuid} from "../../utils";


    export default {
        name: "login",
        data() {
            return {
                formData: {
                    // companyCode: '',
                    username: '',
                    password: '',
                    platformCaptcha:''
                    // randomText: '',
                    // uuid: uuid()
                },
                rules: {
                    // companyCode: [
                    //     {required: true, message: '请输入公司编码', trigger: 'blur'}
                    // ],
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'}
                    ],
                    // randomText: [
                    //     {required: true, message: '请输入验证码', trigger: 'blur'}
                    // ],
                },
                isLogin: false,
                isSubmit: false,
                codeUrl: process.env.VUE_APP_BASEAPI + `login/getCode?uuid=`
            }
        },
        computed: {},
        watch: {
            formData: {
                handler: function (val) {
                    // if (val.username && val.password && val.randomText) {
                    //     this.isSubmit = true
                    // }
                    if (val.username && val.password ) {
                        this.isSubmit = true
                    }
                },
                deep: true
            },
        },
        methods: {
            ...mapActions([
                'login'
            ]),
            handleLogin(e) {
                this.isLogin = true
                this.$refs.loginForm.validate(async (valid) => {
                    if (valid) {
                        try {
                            await this.login(this.formData)
                            setTimeout(() => {
                                this.$router.push('/')
                                this.isLogin = false
                            }, 300)
                        } catch (e) {
                            this.isLogin = false
                            if (e.code === 302) {
                                try {
                                    await this.$confirm(e.msg, '提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'error'
                                    })
                                } catch (d) {

                                }
                            } else if (e.code === 308) {
                                try {
                                    await this.$confirm(e.msg, '提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'error'
                                    })
                                } catch (d) {

                                }
                            }
                            else {
                                this.$message({
                                    message: e.msg,
                                    type: 'error',
                                    duration: 3 * 1000
                                })
                            }

                            console.log(e)
                        }
                    } else {
                        this.isLogin = false
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            hanndleChangeCode() {
                this.formData.uuid = uuid()
            },
            enterLogin(){
                document.onkeydown = (e) =>{   //按下回车提交
                    let key =  window.event.keyCode;
                    if (key === 13) {
                        this.handleLogin()
                    }
                };
            }

        },
        mounted() {
            this.enterLogin()
        }
    }
</script>

<style scoped lang="less">
    .login-pages {
        position: absolute;
        width: 100%;
        height: 100%;
        perspective: 1000px;
        background-image: url("../../assets/cloud.jpg");
        background-size: cover;
    }

    .login-window {
        width: 420px;
        /*height: 420px;*/
        padding: 60px;
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 1);
        transition: all .3s ease-in;
        &.action {
            /*transform: translate(-50%, 2000px) rotateX(45deg) rotateZ(-30deg) rotateY(-45deg);*/
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            filter: blur(20px);
            z-index: -1;
        }
        .title {
            font-size: 24px;
            margin-bottom: 40px;
            color: #303133;
        }
        &.cw-input-code {
            padding-right: 0;
        }

        .cw-icon {
            color: #606266;
            margin-right: 14px;
        }
        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0) inset; //使用足够大的纯色内阴影覆盖黄色背景
        }

        input:-webkit-autofill {
            background-color: rgba(255, 255, 255, 0);
            background-image: none;
            color: #606266;
        }

        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: #606266;
            opacity: 1;

        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: #606266;
            opacity: 1;
        }
        /* 以上两个属性分别针对火狐浏览器不同版本 */

        input:-ms-input-placeholder { /* IE */
            color: #606266;
            opacity: 1;
        }
        input::-webkit-input-placeholder { /* webkit内核的浏览器，如谷歌，edge */
            color: #606266;
            opacity: 1;
        }

        input::placeholder { /* 去掉私有前缀，谷歌和火狐有效；IE和edge无效 */
            color: #606266;
            opacity: 1;

        }
        .code-img {
            display: block;
            width: 100px;
            height: 40px;
            cursor: pointer;
            box-sizing: border-box;
            border: 1px solid #DCDFE6;
            border-left: 0;
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
        }
        .el-form-item.is-error .el-input__inner + .el-input-group__append .code-img,
        .el-form-item.is-error .el-input__inner:focus + .el-input-group__append .code-img,
        .el-form-item.is-error .el-textarea__inner + .el-input-group__append .code-img,
        .el-form-item.is-error .el-textarea__inner:focus + .el-input-group__append .code-img,
        .el-message-box__input input.invalid + .el-input-group__append .code-img,
        .el-message-box__input input.invalid:focus + .el-input-group__append .code-img {
            border-color: #F56C6C;

        }
        .el-input.is-active .el-input__inner + .el-input-group__append .code-img,
        .el-input__inner:focus + .el-input-group__append .code-img {
            border-color: #409EFF;
        }
        .login-button-wrapper {
            margin-top: 30px;
        }

    }

    .cms-title {
        position: absolute;
        width: 100%;
        bottom: -96px;
        left: 0;
        color: #ffffff;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before, &:after {
            content: '';
            position: absolute;
            height: 1px;
            width: 40px;
            top: 50%;
            transform: translateY(-50%);
            background-color: #ffffff;
        }
        &:before {
            left: 80px;
        }
        &:after {
            right: 80px;
        }
    }
</style>
